<template>
  <div class="init-setting">
    <div class="init-setting__warn-list">
      <div class="init-setting__warn">
        Уважаемый пользователь, если на любом из этапой у вас возникнут трудность, вы можете обратить в поддержку приложения.
        Мы бысплатно производим любую первоначальную настройку приложения
      </div>
    </div>


    <div class="init-setting__title">
      1. Установка приложения и корректная работа
    </div>

    <p style="color: var(--color-yellow);" class="init-setting__title-2">
      1.1. Результат
    </p>
    <p class="init-setting__text">
      Для того, чтобы проверить корректность работаты приложения необходимо после его установки перейти на страницу товара, которого нет в наличии.
      Это может быть страница где товар ответствует полностью, или страница где отсутствуют в наличии лишь некоторые варианты (набор опций) товара.
    </p>

    <p class="init-setting__text">
      В случае, если все работает корректно, вместо кнопки "Добавить в корзину" у отсутствующего товара появится кнопка "Следить за наличием".
      При нажатии на эту кнопку всплывает окошко с формой подписки где можно указать email.
    </p>

    <p style="color: var(--color-red);" class="init-setting__title-2">
      1.2. Проблемы
    </p>

    <p class="init-setting__text">
      <b>Нет кнопки "Следить за наличием".</b> В случае, если у отсутствующего товара, не появляется кнопка следить за наличием, необходимо ее добавить.
      Найдите подходчщие место в liquid шаблоне или в виджете карточки товара и добавьте следующий код:

      <pre class="hljs" style="display: block; overflow-x: auto; padding: 0.5em; background: rgb(136 136 136); border-radius: 30px; padding: 30px; color: rgb(255, 255, 255);">{% unless product.available %}
      <span class="hljs-tag" style="color: rgb(98, 200, 243);">&lt;<span class="hljs-name" style="font-weight: 700;">button</span> <span class="hljs-attr">type</span>=<span class="hljs-string" style="color: rgb(162, 252, 162);">"button"</span> <span class="hljs-attr">class</span>=<span class="hljs-string" style="color: rgb(162, 252, 162);">"pnn-btn-js-open-modal"</span>&gt;</span>Товара нет в наличии<span class="hljs-tag" style="color: rgb(98, 200, 243);">&lt;/<span class="hljs-name" style="font-weight: 700;">button</span>&gt;</span>
  {% endunless %}</pre>
    </p>

    <p class="init-setting__text">
      <b>Не появляется всплываюшее окно.</b> В случае если при нажатии на кнопку не появлятеся всплываюшее окно, необходимо убедиться что у кноки присутствует класс
      <b>"pnn-btn-js-open-modal"</b>. В случае если данный класс отсутствует - добавьте его, или заменить кнопку в liquid шаблоне кнопкой из примера выше.
    </p>

    <p style="color: var(--color-green);" class="init-setting__title-2">
      1.3. Решение
    </p>

    <p class="init-setting__text">
      Мы понимаем, что редактирование кода - непростая задача. Поэтому, мы готов помочь вам БЕСПЛАТНО с любой проблемой.
      В случае, если вы хотите воспользоваться услугой интеграции с приложением, пожалуйста, сообщите нам об этом в <b><a target="_blank" href="https://t.me/chelnokov1997">телеграм</a></b>,
      на почту <b><a target="_blank" href="mailto:pateder@yandex.ru">pateder@yandex.ru</a></b> или в чате приложения.
    </p>

    <p class="init-setting__text">
      Интеграция, которые мы произведем, займет около 30 минут и никак не нарушит работу вашего сайта!
    </p>



  </div>
</template>

<script>
export default {
  name: 'init-setting'
}
</script>

<style lang="scss">
.init-setting {
  padding: 30px;

  &__warn-list {
    margin-bottom: 30px;
  }
  &__warn {
    border: 1px solid var(--color-green);
    background-color: #89d7bd;
    padding: 30px;
    border-radius: 30px;
    font-weight: 600;
    max-width: 600px;
  }

  &__title {
    font-size: 26px;
    font-weight: 600;
  }
  &__title-2 {
    font-size: 20px;
    font-weight: 600;
    margin-left: 20px;
  }
  &__text {
    font-size: 16px;
    margin-left: 30px;
  }
}
</style>